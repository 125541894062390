<form class="uploader" target="preview-uploader-fallback" action="{{path}}" method="post" enctype="multipart/form-data" ng-class="{'uploader-legacy': !supported}">
    <div class="uploader__browse">
        <a class="uploader__browse__button button">Choose file:</a>
        <div class="uploader__browse__input">
            <div class="uploader__browse__progressbar processing" ng-hide="(status !== 'processing')"></div>
            <span>{{fileName || "no file selected"}}</span>
        </div>
        <input type="file" name="image" size="1" accept="image/*" title="Choose another file">
    </div>

    <div class="uploader__preview" ng-if="(file.hasValue())" style="background-image: url('{{file.value()}}');"></div>

    <p class="menu__content__comment" ng-if="(previewAdapter === 'canvas')">Preview for your uploaded image file is not available.</p>

    <iframe name="preview-uploader-fallback" src="about:blank" width="0" height="0"></iframe>
</form>
