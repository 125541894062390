
<div class="menu__content__separator">
    <div class="menu__content__title">Placement</div>
    <tile-list option-data="sectionData.decoData.placement" tile-name="placement"></tile-list>
</div>

<div class="menu__content__separator" ng-if="sectionData.decoData.size">
    <div class="menu__content__title">Size</div>
    <p class="menu__content__comment" ng-if="sectionData.number">The number sizes show a graphical estimate of how the numbers might appear on jersey. If the number sizes selected do not fit your jersey sizes in manufacturing, Rawlings will confirm the closest number sizes to your goal.</p>
    <tile-list option-data="sectionData.decoData.size"></tile-list>
</div>

<div class="menu__content__separator form" ng-if="sectionData.text">
    <div class="menu__content__title">Text</div>
    <text-input option-data="sectionData.text" regexp="A-Z0-9\s" placeholder="---" maxlength="15" debounce="500"></text-input>
    <small>Limited to 15 letters, numbers or spaces.</small>
</div>

<div class="menu__content__separator form" ng-if="sectionData.number">
    <div class="menu__content__title">Number</div>
    <text-input option-data="sectionData.number" regexp="\d" maxlength="2" placeholder="--" debounce="500"></text-input>
</div>

<div class="menu__content__separator" ng-if="sectionData.decoData.font">
    <div class="menu__content__title">Font</div>
    <tile-list option-data="sectionData.decoData.font" tile-name="font"></tile-list>
</div>

<div class="menu__content__separator" ng-if="(sectionData.decoData.layout.legalValues().length > 1)">
    <div class="menu__content__title">Configuration</div>
    <tile-list option-data="sectionData.decoData.layout"></tile-list>
</div>

<div class="menu__content__separator" ng-if="(sectionData.decoData.fill.pattern.legalValues().length > 1)">
    <div class="menu__content__title">Sublimated Design</div>
    <tile-list option-data="sectionData.decoData.fill.pattern" tile-name="fillPattern" tile-template="embed"></tile-list>
</div>

<div class="menu__content__separator" ng-if="(sectionData.decoData.fill.pattern.item().colorCount >= 1)">
    <div class="menu__content__title" ng-if="(sectionData.decoData.fill.pattern.value() === 'solidColor')" ng-bind="((sectionData.decoData.fill.pattern.item().colorCount === 1) ? 'Fill Color' : 'Fill Color 1')"></div>
    <div class="menu__content__title" ng-if="(sectionData.decoData.fill.pattern.value() !== 'solidColor')" ng-bind="((sectionData.decoData.fill.pattern.item().colorCount === 1) ? 'Design Color' : 'Design Color 1')"></div>
    <tile-list option-data="sectionData.decoData.fill.color_1" tile-template="color"></tile-list>
</div>

<div class="menu__content__separator" ng-if="(sectionData.decoData.fill.pattern.item().colorCount >= 2)">
    <div class="menu__content__title">Design Color 2</div>
    <tile-list option-data="sectionData.decoData.fill.color_2" tile-template="color"></tile-list>
</div>

<div class="menu__content__separator" ng-if="(sectionData.decoData.fill.pattern.item().colorCount >= 3)">
    <div class="menu__content__title">Design Color 3</div>
    <tile-list option-data="sectionData.decoData.fill.color_3" tile-template="color"></tile-list>
</div>

<div class="menu__content__separator" ng-if="(sectionData.decoData.fill.pattern.item().colorCount >= 4)">
    <div class="menu__content__title">Design Color 4</div>
    <tile-list option-data="sectionData.decoData.fill.color_4" tile-template="color"></tile-list>
</div>


<div class="menu__content__separator" ng-if="sectionData.decoData.outline_1.hasLegalValues()">
    <div class="menu__content__title" ng-bind="sectionData.decoData.outline_2.hasLegalValues() ? 'Outline Color 1' : 'Outline Color'"></div>
    <tile-list option-data="sectionData.decoData.outline_1" tile-template="color"></tile-list>
</div>

<div class="menu__content__separator" ng-if="sectionData.decoData.outline_2.hasLegalValues()">
    <div class="menu__content__title">Outline Color 2</div>
    <tile-list option-data="sectionData.decoData.outline_2" tile-template="color"></tile-list>
</div>


<div ng-if="sectionData.decoData.tailing && sectionData.decoData.tailing.enabled.legalValues().length > 1">
    <div class="menu__content__heading">Tailing</div>

    <div class="menu__content__separator">
        <tile-list option-data="sectionData.decoData.tailing.enabled"></tile-list>
    </div>

    <div ng-if="(sectionData.decoData.tailing.enabled.value() === 'on')">
        <div class="menu__content__separator form">
            <div class="menu__content__title">Text</div>
            <text-input option-data="sectionData.decoData.tailing.text" regexp="A-Z0-9\s" placeholder="---" maxlength="15" debounce="500" novalidate="novalidate" ng-trim="false"></text-input>
        </div>

        <div class="menu__content__separator">
            <div class="menu__content__title">Font</div>
            <tile-list option-data="sectionData.decoData.tailing.font" tile-name="font"></tile-list>
        </div>

        <div class="menu__content__separator">
            <div class="menu__content__title">Fill Color</div>
            <tile-list option-data="sectionData.decoData.tailing.fill" tile-template="color"></tile-list>
        </div>

        <div class="menu__content__separator">
            <div class="menu__content__title">Outline Color</div>
            <tile-list option-data="sectionData.decoData.tailing.outline" tile-template="color"></tile-list>
        </div>

        <div class="menu__content__separator">
            <div class="menu__content__title">Size</div>
            <tile-list option-data="sectionData.decoData.tailing.size"></tile-list>
        </div>
    </div>
</div>
