<div class="menu__content__separator" ng-if="(sectionData.fabric.legalValues().length > 1)">
    <div class="menu__content__title">Fabric</div>
    <tile-list option-data="sectionData.fabric" tile-name="fabric" tile-template="embed"></tile-list>
</div>

<div class="menu__content__separator" ng-if="(sectionData.fillPattern.legalValues().length > 1 || sectionData.fillPattern.item().colorCount === 0)">
    <div class="menu__content__title">Pattern</div>
    <tile-list option-data="sectionData.fillPattern" tile-name="fillPattern" tile-template="embed"></tile-list>
</div>

<div class="menu__content__separator" ng-if="(sectionData.fillPattern.item().colorCount >= 1)">
    <div class="menu__content__title" ng-bind="((sectionData.fillPattern.item().colorCount === 1) ? 'Color' : sectionData.fillPattern.item().colorNames[0] || 'Color 1')"></div>
    <tile-list option-data="sectionData.colors.color_1" tile-template="color"></tile-list>
</div>

<div class="menu__content__separator" ng-if="(sectionData.fillPattern.item().colorCount >= 2)">
    <div class="menu__content__title" ng-bind="(sectionData.fillPattern.item().colorNames[1] || 'Color 2')">Color 2</div>
    <tile-list option-data="sectionData.colors.color_2" tile-template="color"></tile-list>
</div>

<div class="menu__content__separator" ng-if="(sectionData.fillPattern.item().colorCount >= 3)">
    <div class="menu__content__title" ng-bind="(sectionData.fillPattern.item().colorNames[2] || 'Color 3')">Color 3</div>
    <tile-list option-data="sectionData.colors.color_3" tile-template="color"></tile-list>
</div>

<div class="menu__content__separator" ng-if="(sectionData.fillPattern.item().colorCount >= 4)">
    <div class="menu__content__title" ng-bind="(sectionData.fillPattern.item().colorNames[3] || 'Color 4')">Color 4</div>
    <tile-list option-data="sectionData.colors.color_4" tile-template="color"></tile-list>
</div>
