<div class="designSummary__section__inner">

    <dl>
        <dd ng-if="item.valueName">{{item.valueName}}</dd>
        <dt>{{item.optionName}}</dt>
    </dl>

    <div ng-repeat="item in item.options" ng-include="'designSummary/item.html'"></div>

</div>
