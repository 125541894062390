<div class="contextMenu" ng-show="visible">
    <ul class="contextMenu-list">
    	<li class="contextMenu-list-item">
    		{{projectTitle}}
    	</li>
    	<li class="contextMenu-list-item">
    		© Content and Application by OrangeLV
    	</li>
    	<li class="contextMenu-list-item mod-Clickable">
	    	<a href="http://www.orangelv.com" target="_blank">http://www.orangelv.com</a>
    	</li>
    </ul>
</div>
