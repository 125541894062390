<div class="designSummary__section">

    <div>
        <dl>
            <dd ng-bind="section.valueName"></dd>
            <dt><h2>{{section.optionName}}</h2></dt>
        </dl>
    </div>

    <div ng-repeat="item in section.options" ng-include="'designSummary/item.html'"></div>

</div>
