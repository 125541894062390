<div id="lightbox" class="lightbox lightbox_black" ng-show="popupName" ng-switch="popupName" ng-click="closePopup($event)">

    <div class="lightbox__wrapper" ng-switch-when="print">
        <div class="lightbox__wrapper">
            <popup ng-if="(popupName === 'print')" type="print">
                <print></print>
            </popup>
        </div>
    </div>

    <div class="scrollable" ng-switch-default>
        <div class="scrollable__container">

            <div class="lightbox__table">
                <div class="lightbox__table__cell">

                    <popup ng-if="(popupName === 'order')" title="{{orderSectionTitle}}">
                        <order></order>
                    </popup>

                    <popup ng-if="(popupName === 'share')" title="{{shareSectionTitle}}">
                        <share></share>
                    </popup>

                    <popup ng-if="(popupName === 'warning')" title="Small screen :(">
                        <warning></warning>
                    </popup>

                </div>
            </div>

        </div>
    </div>

</div>
