<div id="navigation" class="navigation" ng-class="{'is_visible': isNavigationVisible, 'is_mobile': isMobile}">
    <div id="navigationWrapper" class="navigation__wrapper">
        <div class="navigation__tabs">
            <ul>
                <li ng-repeat="tabCode in tabList.allCodes" ng-class="{'active': (tabCode === activeTab)}" ng-click="openTab(tabCode)">
                    <i class="icon icon_{{tabCode}}"></i>
                    <span>{{tabList.itemMap[tabCode].name}}</span>
                </li>
            </ul>
        </div>

        <div class="menu" ng-controller="MenuController" ng-include="menuTemplate" ng-class="{'is_backward': (tabDirection === 'backward')}" ng-render-finished></div>
    </div>
</div>
