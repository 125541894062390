<ul class="tiles tiles_{{tileColumns}} tiles_{{tileName}}">
    <li ng-if="enableProperty">
        <button class="button button_block button_tile" ng-class="{'selected': (enableProperty.value() === 'off')}" ng-click="disable()">None</button>
    </li>

    <li ng-repeat="itemCode in itemList">
        <tile ng-click="selectItem(itemCode)"></tile>
    </li>

    <li ng-if="expandable">
        <div class="tiles__tile tiles__tile_transparent" ng-click="toggleExpand()">
            <div class="tiles__item tiles__item_1x1 tiles__item_expand">
                <span class="tiles__text tiles__text_middle">
                    <span ng-if="!expanded">More&hellip;</span>
                    <span ng-if="expanded">Less&hellip;</span>
                </span>
            </div>
        </div>
    </li>
</ul>
