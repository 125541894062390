<div class="share" ng-class="{'loading cursor_wait overlay': (saveStatus === 'processing')}">
    <ul class="tiles tiles_quadruple" ng-class="{'blur': (saveStatus === 'processing')}">
        <li>
            <div class="tiles__tile">
                <div class="tiles__item tiles__item_1x1 tiles__item_share">
                    <a class="share__icon" ng-click="share('facebook')">
                        <i class="icon icon_facebook"></i>
                    </a>
                </div>
            </div>
        </li>
        <li>
            <div class="tiles__tile">
                <div class="tiles__item tiles__item_1x1 tiles__item_share">
                    <a class="share__icon" ng-click="share('twitter')">
                        <i class="icon icon_twitter"></i>
                    </a>
                </div>
            </div>
        </li>
        <li>
            <div class="tiles__tile">
                <div class="tiles__item tiles__item_1x1 tiles__item_share">
                    <a class="share__icon" ng-click="share('google')">
                        <i class="icon icon_google"></i>
                    </a>
                </div>
            </div>
        </li>
        <li>
            <div class="tiles__tile">
                <div class="tiles__item tiles__item_1x1 tiles__item_share">
                    <a class="share__icon" href="{{mailtoLink}}" target="_blank">
                        <i class="icon icon_email"></i>
                    </a>
                </div>
            </div>
        </li>
    </ul>
</div>
