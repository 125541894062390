<div class="printContainer" ng-class="{'loading': state.loading}">
    <div class="printContainer__top">
        <vendor-logo></vendor-logo>

        <div class="printContainer__heading">
            <print-title></print-title>
            <p ng-if="urlService.getProjectID()">Configuration ID: {{urlService.getProjectID()}}</p>
        </div>
    </div>

    <div class="printContainer__items" id="printContainerItems">
        <img ng-src="{{snapshot.imgUrl}}" ng-repeat="snapshot in snapshots">
        <span ng-if="state === 'error' && !snapshots.length">Images unavailable</span>
    </div>

    <div class="printContainer__designSummary">
        <design-summary status="expanded" printable="true"></design-summary>
    </div>
</div>
