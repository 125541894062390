<div class="menu__content__separator">
    <div class="menu__content__title">Placement</div>

    <tile-list option-data="sectionData.placement" tile-name="placement"></tile-list>
</div>

<div class="menu__content__separator" ng-if="sectionData.size">
    <div class="menu__content__title">Size</div>
    <tile-list option-data="sectionData.size"></tile-list>
</div>

<div class="menu__content__separator" ng-if="sectionData.customFile">
    <div class="menu__content__title">Custom file</div>
    <p class="menu__content__comment">
        Files uploaded to builder are for visualization purposes only. Artwork intended for production use should be submitted via e-mail. (preferred file types high resolution .ai or .eps)
    </p>
    <uploader template="uploader" file="sectionData.customFile"></uploader>
</div>

<div class="menu__content__separator" ng-if="sectionData.design">
    <div class="menu__content__title">Stock logo</div>
    <tile-list option-data="sectionData.design" tile-name="logo" tile-template="embed" expandable="8"></tile-list>
</div>

<div class="menu__content__separator" ng-if="sectionData.colors && !sectionData.colors.color_1.isDisabled()">
    <div class="menu__content__title" ng-if="sectionData.colors.color_2.isDisabled()">Logo Color</div>
    <div class="menu__content__title" ng-if="!sectionData.colors.color_2.isDisabled()">Logo Color 1</div>
    <tile-list option-data="sectionData.colors.color_1" tile-template="color"></tile-list>
</div>

<div class="menu__content__separator" ng-if="sectionData.colors && !sectionData.colors.color_2.isDisabled()">
    <div class="menu__content__title">Logo Color 2</div>
    <tile-list option-data="sectionData.colors.color_2" tile-template="color"></tile-list>
</div>

<div class="menu__content__separator" ng-if="sectionData.colors && !sectionData.colors.color_3.isDisabled()">
    <div class="menu__content__title">Logo Color 3</div>
    <tile-list option-data="sectionData.colors.color_3" tile-template="color"></tile-list>
</div>
