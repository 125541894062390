<div class="popup popup_standart" ng-click="$event.stopPropagation()">
    <div class="popup__close" ng-click="closePopup($event)"><i class="icon icon_close"></i></div>

    <div class="popup__header">
        <h1>{{item.title}}</h1>
        <h3>{{item.code}}</h3>
    </div>

    <div class="popup__content">
        <p>Here goes item description{{item.description}}</p>
    </div>
</div>
